// @codekit-prepend "extern/jquery.justifiedGallery.js"
// @codekit-prepend "extern/jquery.fancybox.js"
// @codekit-prepend "extern/swiper-min.js"

jQuery(document).ready(function ($) {
	// KLIK FUNCTIES +++++++++++++++++++++++++++ //

	// Hamburger toggle -----------
	$('.hamburger').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Algemene toggle -----------
	$('.toggle').on('click', function () {
		$(this).toggleClass('actief');
	});

	// Actieveren popup -----------
	$('.contact-trig').on('click', function () {
		$('.popup-alg').addClass('toon');
		$('body').addClass('noscroll');
	});
	$('.inner .sluiten ,body').on('click', function () {
		$('.popup-alg').removeClass('toon');
		$('body').removeClass('noscroll');
	});
	$('.contact-trig,.popup-alg .inner').click(function (e) {
		e.stopPropagation();
	});

	$('.mag-trig').on('click', function () {
		$('.popup-mag').addClass('toon');
		$('body').addClass('noscroll');
	});
	$('.inner .sluiten ,body').on('click', function () {
		$('.popup-mag').removeClass('toon');
		$('body').removeClass('noscroll');
	});
	$('.mag-trig,.popup-mag .inner').click(function (e) {
		e.stopPropagation();
	});

	// Sluiten van algemene melding
	$('.alg_melding .sluiten').click(function () {
		$('.alg_melding').css('display', 'none');
	});

	// Cookie consent wanneer toegestaan
	$('.cookie-accept').click(function () {
		$('.cookie').addClass('hidden');
		Cookies.set(
			'cookie.accept',
			'true',
			{ element: '.cookie' },
			{ expires: 365 }
		);
	});
	if (Cookies.get('cookie.accept')) {
		$('.cookie').addClass('keep-hidden');
	}

	// Cookie consent wanneer verborgen
	$('.cookie-hide').click(function () {
		$('.cookie').addClass('hidden');
		Cookies.set('cookie.hide', 'true', { element: '.cookie' }, { expires: 30 });
	});
	if (Cookies.get('cookie.hide')) {
		$('.cookie').addClass('keep-hidden');
	}

	// Cookie consent wanneer toegestaan
	$('.sidepatch .sluiten').click(function () {
		$('.sidepatch').addClass('hidden');
		Cookies.set(
			'sidepatch.hidden',
			'true',
			{ element: '.sidepatch' },
			{ expires: 365 }
		);
	});
	if (Cookies.get('sidepatch.hidden')) {
		$('.sidepatch').addClass('hide');
	}

	// SCROLL FUNCTIES ++++++++++++++++++++++++++ //

	// Back to top -----------
	$('.backtotop').click(function () {
		$('html, body').animate({ scrollTop: 0 }, 600);
		return false;
	});

	$(window).scroll(function () {
		var scroll = $(window).scrollTop();
		if ($(window).scrollTop() > 400) {
			$('.backtotop').addClass('toon');
		} else if ($(window).scrollTop() <= 400) {
			$('.backtotop').removeClass('toon');
		}
	});

	$(window).scroll(function () {
		if ($(window).scrollTop() + $(window).height() == $(document).height()) {
			$('.backtotop').addClass('fix');
		} else {
			$('.backtotop').removeClass('fix');
		}
	});

	// HULPMIDDELEN +++++++++++++++++++++++++++ //

	// Dummy uitlichten -----------
	$("h1:contains('titel')").css('color', 'red');

	// ANIMATIES +++++++++++++++++++++++++++ //

	// WOW animaties uitschakelen op mobiel -----------
	if ($(window).width() <= 1024) {
		$('.wow').removeClass('wow');
	}

	// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
	//-----------------------------------------------------------------------------------------------------------//

	var swiper_lp = new Swiper('.swiper-lp', {
		slidesPerView: 1,
		navigation: {
			nextEl: '.swiper-custom-next',
			prevEl: '.swiper-custom-prev'
		}
	});

	// // Select all links with hashes
	// $('a[href*="#"]')
	// 	// Remove links that don't actually link to anything
	// 	.not('[href="#"]')
	// 	.not('[href="#0"]')
	// 	.click(function(event) {
	// 		// On-page links
	// 		if (
	// 			location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
	// 			&&
	// 			location.hostname == this.hostname
	// 		) {
	// 			// Figure out element to scroll to
	// 			var target = $(this.hash);
	// 			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	// 			// Does a scroll target exist?
	// 			if (target.length) {
	// 				// Only prevent default if animation is actually gonna happen
	// 				event.preventDefault();
	// 				$('html, body').animate({
	// 					scrollTop: target.offset().top
	// 				}, 1000, function() {
	// 					// Callback after animation
	// 					// Must change focus!
	// 					var $target = $(target);
	// 					$target.focus();
	// 					if ($target.is(":focus")) { // Checking if the target was focused
	// 						return false;
	// 					} else {
	// 						$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
	// 						$target.focus(); // Set focus again
	// 					};
	// 				});
	// 			}
	// 		}
	// 	});

	// Test pagina block loader
	$('#btn-test-1').click(function () {
		$('.single-block').each(function () {
			$(this).removeClass('block-active');
			$(this).addClass('block-hidden');
		});
		$('.single-block.block-2').removeClass('block-hidden');
		$('.single-block.block-2').addClass('block-active');
	});

	$('.test-option').click(function () {
		$('.single-block').each(function () {
			$(this).removeClass('block-active');
			$(this).addClass('block-hidden');
		});
		$('.single-block#form-' + $(this).attr('data-option')).removeClass(
			'block-hidden'
		);
		$('.single-block#form-' + $(this).attr('data-option')).addClass(
			'block-active'
		);
	});
});
